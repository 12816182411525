<template>
  <v-container fluid grid-list-xl>
    <v-card v-if="isLoading" class="pa-3">
      <v-progress-linear
        color="white"
        indeterminate
        rounded
        height="3"
      />
    </v-card> 
    <div v-else>
      <upload-meme-template-modal @close-avatar-modal="reloadPage" />
      <v-layout>
        <v-flex xs12>
          <v-card v-if="!memes.length" class="pa-4 text-xs-center">
            No photos uploaded yet.
          </v-card>

          <v-card v-else>
            <v-container fluid>
              <v-layout row wrap>
                <v-flex
                  v-for="(meme, index) in memes"
                  :key="meme.id"
                  xs12
                  sm4
                  md3
                  lg2
                >
                  <v-card
                    v-if="!meme.isDeleted"
                    flat
                    tile
                    class="img-item-wrap"
                  >
                    <div class="image-wrap">
                      <img :src="meme.picture" alt="" class="avatar-img">
                    </div>
                    <div class="img-item-hover">
                      <div class="img-btn-block">
                        <v-btn
                          color="error"
                          :loading="meme.isLoading"
                          class="ma-0"
                          @click="deleteItem(meme.id, index)"
                        >
                          Delete
                        </v-btn>
                      </div>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>

            <v-layout row align-center>
              <v-flex xs12>
                <div class="text-xs-center mt-4">
                  <div class="text-xs-center">
                    <v-pagination
                      v-model="pagination.page"
                      :length="pages"
                      :total-visible="10"
                      @input="refreshPaginationPage"
                    />
                  </div>
                </div>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </div>
  </v-container>
</template>

<script>
import UploadMemeTemplateModal from "@/components/MemeTemplate/MemeTemplateModal"
import SavesPagination from "../../../../mixins/SavesPaginationMixin"
import {
  getMemeTemplate,
  deleteMemeTemplate
} from "../../../../api/memeTemplate"
import { fetchData } from "../../../../utils/response"
export default {
  name: "SettingsMemeTemplate",
  components: {
    UploadMemeTemplateModal
  },
  mixins: [SavesPagination],
  data: () => ({
    isLoading: false,
    pagination: {
      page: 1,
      rowsPerPage: 24
    },
    total: 0,
    memes: {}
  }),
  computed: {
    pages() {
      if (this.pagination.rowsPerPage == null || this.total == null) return 0

      return Math.ceil(this.total / this.pagination.rowsPerPage)
    }
  },
  mounted() {
    const pagination = this.$paginationCacheGet()
    this.pagination.page = pagination.page
    this.pagination.rowsPerPage = 24
    this.$paginationCacheSave(
      this.pagination.page,
      this.pagination.rowsPerPage
    )
  },
  created() {
    this.fetchAvatarData()
  },
  methods: {
    deleteItem(id, index) {
      this.$set(this.memes[index], "isLoading", true)
      const idDelete = {
        template_id: id
      }
      deleteMemeTemplate(idDelete)
        .then(response => {
          this.$notificationShow(response.data.data)
          this.$set(this.memes[index], "isDeleted", true)
        })
        .catch(this.$notificationHandleResponseError)
        .finally(() => {
          this.$set(this.memes[index], "isLoading", false)
        })
    },
    async fetchAvatarData() {
      const params = {
        page: this.pagination.page,
        per_page: this.pagination.rowsPerPage
      }
      this.isLoading = true

      this.memes = await getMemeTemplate(params)
        .then(response => {
          this.total = response.data.meta.total
          return response
        })
        .then(fetchData)
        .then(data =>
          data.map(user => {
            return user
          })
        )
        .catch(this.$notificationHandleResponseError)
        .finally(() => (this.isLoading = false))
    },
    refreshPaginationPage() {
      this.fetchAvatarData()
      this.$paginationCacheSave(
        this.pagination.page,
        this.pagination.rowsPerPage
      )
    },
    reloadPage() {
      this.fetchAvatarData()
    }
  }
}
</script>

<style scoped>
.img-item-wrap {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar-img {
  max-width: 100%;
  height: auto;
  max-height: 150px;
}
</style>
