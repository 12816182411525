import api from "./index"

/**
 * Get random Avatars
 *
 * @return {*} 
 */
export const getMemeTemplate = (params, includes = []) => {
  return api
    .request(`admin/template`)
    .withParams(params)
    .withIncludes(includes)
    .get()
}

/**
 * Delete random Avatars
 *
 * @return id
 */
export const deleteMemeTemplate = params => {
  return api
    .request(`admin/template`)
    .withParams(params)
    .delete()
}
