<template>
  <div>
    <v-btn color="success" class="ma-0" @click="dialog = true">
      Upload new image
    </v-btn>

    <v-dialog v-model="dialog" max-width="920px"> 
      <v-card>
        <v-card-title>
          <v-layout align-center justify-space-between>
            <span class="headline">{{ formTitle }}</span>

            <v-btn icon dark class="ma-0" @click.native="close">
              <v-icon>close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-title>

        <v-card-text>
          <div>
            <dropzone
              id="AvatarDropzone"
              ref="MemeDropzone"
              :options="options"
              @vdropzone-complete-multiple="fileDropComplete"
              @vdropzone-error="addDropError"
            />
          </div>
        </v-card-text>

        <v-card-actions class="px-3 pt-0">
          <v-spacer />
          <v-btn color="error" @click.native="close">
            Close
          </v-btn>
          <!--                    <v-btn color="success" @click.native="save">Upload</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { apiUrl } from "../../config"
import Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"
import { log } from "../../utils/helpers"
export default {
  name: "MemeTemplateModal",
  components: {
    Dropzone
  },
  data() {
    return {
      formTitle: "Upload new image",
      dialog: false,
      langSettings: {
        dictResponseError: "A custom error message"
      },
      options: {
        url: "",
        thumbnailWidth: 150,
        maxFilesize: 100,
        timeout: 120000,
        uploadMultiple: true,
        autoQueue: true,
        autoProcessQueue: true,
        paramName: "files",
        acceptedFiles: "image/jpeg,image/png",
        headers: {
          Accept: "application/json, text/plain, */*",
          Authorization: `Bearer ${this.$ls.get("access_token")}`
        }
      }
    }
  },
  created() {
    this.options.url = `${apiUrl}admin/template`
  },
  methods: {
    fileDropComplete(response) {
      log(response, "fileDropComplete")
    },
    // addDropFile(file) {
    //   // log(file, "addDropFile");
    // },
    // fileDropSuccess(file, response) {
    //   // log(file, response, "fileDropSuccess");
    // },
    addDropError() {
      const elements = document.querySelectorAll(".dz-error-message span")
      if (elements && elements.length) {
        elements[0].innerHTML =
          "That only jpg, jpeg and png formats are available!"
      }
    },
    close() {
      this.$emit("close-avatar-modal")
      this.dialog = false
    }
    // save() {
    //   this.close();
    // }
  }
}
</script>

<style scoped></style>
